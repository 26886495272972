import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Microphone" link="https://www.lekoarts.de/design/introducing-the-theme-ui-plugin-for-figma?utm_source=cara&utm_medium=Theme" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  -
    </ProjectCard>
    <ProjectCard title="Medium articles as Huawei Developer" link="https://isilsucitim90.medium.com" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  A collection of short, but useful quick tips shared by me and the community
  about HMS Kits and my own project's brief explanation. 
    </ProjectCard>
    <ProjectCard title="Huawei ML Kit Landmark Recognition's codelab" link="https://medium.com/huawei-developers/wtracking-app-with-map-and-location-kit-d2b54eb66522" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Huawei ML Kit Landmark Recognition's codelab.
    </ProjectCard>
    <ProjectCard title="Huawei Push Kit Android Library" link="https://medium.com/huawei-developers/everything-you-need-to-know-about-hms-ml-kits-landmark-recognition-feature-c512942bc886" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  -
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      