import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I am a +3 years experienced Software Developer. I am proficient in various programming languages such as
JavaScript, Kotlin at advanced level and Swift at intermediate level. I’m enthusiastic about designing and
developing features end to end.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      